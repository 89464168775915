import * as React from 'react';
import { Link, HeadFC, PageProps } from 'gatsby';
import Layout from '../layouts/Layout';
import arrow from '../images/white-arrow-right.svg';
const pageStyles = {
	color: '#232129',
	padding: '96px',
	fontFamily: '-apple-system, Roboto, sans-serif, serif'
};
const headingStyles = {
	marginTop: 0,
	marginBottom: 64,
	maxWidth: 320
};

const paragraphStyles = {
	marginBottom: 48
};
const codeStyles = {
	color: '#8A6534',
	padding: 4,
	backgroundColor: '#FFF4DB',
	fontSize: '1.25rem',
	borderRadius: 4
};

const NotFoundPage: React.FC<PageProps> = () => {
	return (
		<Layout
			title="Wallis Co. | Employment Opportunities"
			desc="Wallis Co. | Employment Opportunities"
		>
			<main style={pageStyles}>
				<div className="mx-auto w-full text-center">
					<h1 className="mx-auto mb-[0px] pt-[65px] text-center font-hind text-[250px] leading-[250px] text-black max-[690px]:pt-0 max-[690px]:text-[150px] max-[690px]:leading-[150px]">
						404
					</h1>
					<h2 className="mx-auto mt-0 font-hind text-[54px] font-light antialiased">
						Page Not Found
					</h2>
					<Link
						className=" mt-[30px] inline-flex rounded-full bg-[#006bb6] py-[11px] pl-[46px] pr-[10px] font-montserrat text-[14px] uppercase leading-4 text-white antialiased hover:text-white"
						to="/"
					>
						<span className=" translate-x-[-18px]  translate-y-2 leading-4 tracking-wider">
							Back Home
						</span>
						<div className="flex h-8 w-8 flex-col items-center justify-center rounded-full bg-[#FFFFFF20]">
							<img src={arrow} className={''} width={11} />
						</div>
					</Link>
				</div>
			</main>
		</Layout>
	);
};

export default NotFoundPage;

export const Head: HeadFC = () => <title>Not found</title>;
